import arrow from 'assets/img/form/success_arrow.png'
import termsBtnActive from 'assets/img/form/terms-btn-active.png'
import termsBtn from 'assets/img/form/terms-btn.png'
import { useGetJoinTerms, useModal } from 'hooks'
import { ReturnValidate } from 'hooks/useValidate'
import { ReactNode } from 'react'
import { SetterOrUpdater, useRecoilValue } from 'recoil'
import { otpTelecom } from 'recoil/otp/atom'
import { ValidateType } from 'recoil/validate/atom'
import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
const QNAContainer = styled.div`
  width: 100%;
`
const QNATermCheckboxContainer = styled.div``
const QNATermCheckbox = styled.input`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: none;
`
const QNATermTitle = styled.label<{ active: boolean }>`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-size: 16px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: -0.05em;
    cursor: pointer;
    text-align: left;
    margin-top: 10px;
    padding-left: 30px;
    background: center left / contain no-repeat url(${(props) => (props.active ? termsBtnActive : termsBtn)});
    background-size: 4%;
    display: flex;
    align-items: center;
    height: 32px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    line-height: 20px;
    margin-top: 10px;
    letter-spacing: -0.05em;
    text-align: left;
    padding-left: 30px;
    background: center left / contain no-repeat url(${(props) => (props.active ? termsBtnActive : termsBtn)});
    display: flex;
    align-items: center;
    height: 20px;
  }
`
const QNATermOpenBtn = styled.button`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: auto auto 2px 10px;
    border: none;
    background: none;
    cursor: pointer;
    > img {
      display: flex;
      margin-left: -15px;
      margin-top: -1px;
      width: 33px;
      height: 33px;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: auto auto 2px 10px;
    border: none;
    background: none;
    cursor: pointer;
    > img {
      display: flex;
      margin-left: -15px;
      margin-top: -8px;
      width: 33px;
      height: 33px;
    }
  }
`
const QNATermSubTitle = styled.p`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 5px 0;
    letter-spacing: -0.05em;
    text-align: left;
    padding-inline-start: 30px;
    color: ${STYLE_GUIDE.COLOR.grey03};
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-size: 16px;
    padding-left: 27px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.05em;
    text-align: left;
    color: ${STYLE_GUIDE.COLOR.grey03};
  }
`
interface QnaTermPropType {
  title: ReactNode
  state: TermsType
  setState: SetterOrUpdater<TermsType>
  validate: ReturnValidate<ValidateType>
  subTitle?: ReactNode
}
const QnaTerm = ({ title, state, setState, validate, subTitle }: QnaTermPropType) => {
  const { data: termsData } = useGetJoinTerms()
  const tel = useRecoilValue(otpTelecom)
  const modal = useModal()

  const onChange = () => {
    setState(state !== 1 ? 1 : 0)
    if (state === 0) {
      validate.recoilState[1]({ valid: false, msg: '' })
    }
  }
  const handleTermsDetail = (value: number) => {
    modal.alert({
      title: '',
      contents: (
        <div className='modal_terms'>
          <p>개인정보 수집 및 이용약관</p>
          <div dangerouslySetInnerHTML={{ __html: termsData ? termsData?.[tel].Terms[value - 1].Contents : '' }} />
        </div>
      ),
      isXBtn: false
    })
  }

  return (
    <QNAContainer>
      <QNATermCheckboxContainer>
        <QNATermTitle active={state === 1}>
          <QNATermCheckbox type='checkbox' onChange={onChange} />
          {title}
          <QNATermOpenBtn onClick={() => handleTermsDetail(2)}>
            <img src={arrow} alt='' />
          </QNATermOpenBtn>
        </QNATermTitle>
      </QNATermCheckboxContainer>
      {subTitle && <QNATermSubTitle>{subTitle}</QNATermSubTitle>}
    </QNAContainer>
  )
}

export default QnaTerm