import { selector } from 'recoil'
import { resLoginUser } from 'recoil/user/atom'
import { btnDisabled, inputDisabled, validateTypeFam } from 'recoil/validate/atom'
import { APIs } from 'service'
import { CMD_TYPE } from 'service/constants/common'
import { RecapAuthToken } from "../common/atom"
import {
  otpAuthType,
  otpCallType,
  otpCarNumber,
  otpCouponCode,
  otpCryptoNumber,
  otpFNumber,
  otpFTelecom,
  otpPNumber,
  otpTelecom,
  otpTermsAgrees,
  // otpTimeOut,
  otpTransctionID,
  resSendOTP
} from './atom'
export const getReqSendOTP = selector<RequestSendOTPBody>({
  key: 'getReqSendOTP',
  get: ({ get }) => {
    return {
      Telecom: get(otpTelecom),
      AuthType: get(otpAuthType),
      CallType: get(otpCallType),
      PNumber: get(otpPNumber),
      PCode: 'autocall_homepage',
      AuthToken: get(RecapAuthToken)
    }
  }
})
export const getReqAddUserOTP = selector<RequestSendOTPBody>({
  key: 'getReqAddUserOTP',
  get: ({ get }) => {
    return {
      Telecom: get(otpFTelecom),
      AuthType: 'fadd',
      CallType: get(otpCallType),
      PNumber: get(otpFNumber)
    }
  }
})
export const otpWithSendReset = selector<ResponseSendOTPBody | undefined>({
  key: 'otpWithSend',
  get: ({ get }) => {
    return get(resSendOTP)
  },
  set: ({ set, reset }, newVal) => {
    reset(otpTelecom)
    reset(otpPNumber)
    reset(otpAuthType)
    reset(otpTransctionID)
    reset(otpCallType)
    reset(otpCryptoNumber)
    reset(validateTypeFam('phoneNumber'))
    reset(validateTypeFam('certNumber'))
    reset(validateTypeFam('carNumber'))
    reset(validateTypeFam('certCarNumber'))
    reset(validateTypeFam('couponNumber'))
    set(inputDisabled('phoneInput'), { isDisabled: false })
    set(inputDisabled('certInput'), { isDisabled: false })
    set(inputDisabled('telecom'), { isDisabled: false })
    set(inputDisabled('sendBtn'), { isDisabled: false })
    set(btnDisabled('certBtn'), { isDisabled: true })
  }
})
export const getReqCertJoinPartner = selector<RequestCertOTPBody>({
  key: 'getReqCertJoinPartner',
  get: ({ get }) => {
    return {
      TransactionID: get(otpTransctionID),
      Telecom: get(otpTelecom),
      PNumber: get(otpPNumber),
      AuthType: 'join_partner',
      CallType: get(otpCallType),
      CryptoNumber: get(otpCryptoNumber),
      PCode: 'autocall_homepage',
      CarNum: get(otpCarNumber),
      CouponCode: get(otpCouponCode),
      TermsAgree: get(otpTermsAgrees).join(""),
    }
  }
})
export const getReqCertJoinReady = selector<RequestCertOTPBody>({
  key: 'getReqCertJoinReady',
  get: ({ get }) => {
    return {
      TransactionID: get(otpTransctionID),
      Telecom: get(otpTelecom),
      PNumber: get(otpPNumber),
      AuthType: 'join_ready',
      CallType: get(otpCallType),
      CryptoNumber: get(otpCryptoNumber),
      PCode: 'autocall_homepage',
      CarNum: get(otpCarNumber),
      CouponCode: get(otpCouponCode),
      TermsAgree: get(otpTermsAgrees).join(""),
    }
  }
})

export const getReqCertLogin = selector<RequestCertOTPBody>({
  key: 'getReqCertLogin',
  get: ({ get }) => {
    return {
      Telecom: get(otpTelecom),
      PNumber: get(otpPNumber),
      AuthType: 'login',
      TransactionID: get(otpTransctionID),
      CallType: get(otpCallType),
      CryptoNumber: get(otpCryptoNumber)
    }
  }
})
export const otpWithLogin = selector<ResponseLoginBody | undefined>({
  key: 'otpWithCertLogin',
  get: async ({ get }) => {
    return await APIs.api.requestSRS<ResponseLoginBody>(CMD_TYPE.CERT_OTP, get(getReqCertLogin))
  },
  set: ({ set, reset }, result) => {
    if (result != null) {
      set(resLoginUser, result)
    }
    reset(otpTelecom)
    reset(otpPNumber)
    reset(otpAuthType)
    reset(otpTransctionID)
    reset(otpCallType)
    reset(otpCryptoNumber)
  }
})
export const getReqCertDelete = selector({
  key: 'getReqCertDelete',
  get: ({ get }) => {
    return {
      Telecom: get(otpTelecom),
      PNumber: get(otpPNumber),
      AuthType: 'delete',
      TransactionID: get(otpTransctionID),
      CallType: get(otpCallType),
      CryptoNumber: get(otpCryptoNumber),
    }
  }
})
export const otpWithDelete = selector<unknown>({
  key: 'otpWithDelete',
  get: () => {},
  set: ({ reset }, result) => {
    if (result) {
      reset(otpTelecom)
      reset(otpPNumber)
      reset(otpAuthType)
      reset(otpTransctionID)
      reset(otpCallType)
      reset(otpCryptoNumber)
    }
  }
})
