import { ReactComponent as RArrow } from 'assets/img/form/termsDetailRightArrow.svg'
import { useGetJoinTerms, useModal } from 'hooks'
import { useAllRequiredTermsAgree } from 'hooks/useGetItems.hook'
import { useCallback, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { otpJoinTermsData, otpJoinTermsList, otpTelecom, otpTermsAgrees } from 'recoil/otp/atom'
import { validateTypeFam } from 'recoil/validate/atom'

const AutocallTerms = () => {
  const modal = useModal()
  const allAgrees = useAllRequiredTermsAgree()

  const { data: joinTerms } = useGetJoinTerms()

  const tel = useRecoilValue(otpTelecom)
  const setJoinTermsList = useSetRecoilState(otpJoinTermsList)
  const joinTermsData = useRecoilValue(otpJoinTermsData)
  const [termsAgrees, setTermsAgrees] = useRecoilState(otpTermsAgrees)
  const setTermsValid = useSetRecoilState(validateTypeFam('terms'))

  const createInitTermsAgrees = useCallback(
    (): number[] => Array(termsAgrees.length).fill(0),
    [termsAgrees]
  )

  const handleClickTerms = (termsOrder: number | undefined) => {
    if (!termsOrder) return false

    setTermsAgrees((old) => {
      const arr = [...old]
      arr[termsOrder - 1] = Math.abs(arr[termsOrder - 1] - 1)
      return arr
    })
  }
  const handleClickAll = () => {
    // 필수약관 전체선택 여부
    if (allAgrees) {
      setTermsAgrees(createInitTermsAgrees())
    } else {
      setTermsAgrees((old) => {
        const copyArray = [...old]

        joinTermsData?.Terms.forEach((value: JoinTermsDetail) => {
          if (value.Order !== undefined) {
            copyArray[value.Order - 1] = value.Required ?? 0
          }
        })
        return copyArray
      })
    }
  }
  const handleTermsDetail = (index: number | undefined) => {
    if (index === undefined) return false
    const terms = joinTermsData?.Terms[index]

    modal.alert({
      title: '',
      contents: (
        <div className='modal_terms'>
          <p>{terms?.Title || ''}</p>
          <div dangerouslySetInnerHTML={{ __html: terms?.Contents || '' }} />
        </div>
      ),
      isXBtn: false
    })
  }

  useEffect(() => {
    return setTermsAgrees(Array(9).fill(0))
  }, [setTermsAgrees])

  useEffect(() => {
    if (joinTerms != null) {
      setJoinTermsList(joinTerms)
    }
  }, [joinTerms, setJoinTermsList])
  useEffect(() => {
    if (allAgrees) {
      setTermsValid({ valid: false, msg: '' })
    }
  }, [tel, termsAgrees, allAgrees, setTermsValid])

  return (joinTermsData != null)
    ? (
      <div className='terms-container'>
        <div className={`terms-checkbox all ${allAgrees ? 'active' : ''}`}>
          <label>
            <input className='terms-input' type='checkbox' name='all' id='checkbox-all' onChange={handleClickAll} />
            전체 약관을 모두 확인했으며 이에 동의합니다.
          </label>
        </div>
        {joinTermsData?.Terms.map(
          (element, index) =>
            !!element.Title && (
              <div key={index} className={`terms-checkbox ${element.Order && termsAgrees[element.Order - 1] === 1 ? 'active' : ''}`}>
                <label>
                  <input
                    className='terms-input'
                    type='checkbox'
                    name={`agree${index}`}
                    id={`agree${index}`}
                    onChange={() => handleClickTerms(element.Order)}
                  />
                  {element.Title}{element.Required !== 1 ? '(선택)' : ''}
                </label>
                <button onClick={() => handleTermsDetail(index)}>
                  <RArrow />
                </button>
              </div>
            ),
        )}
      </div>
      )
    : (
      <div />
      )
}

export default AutocallTerms