import { useGetHomeTerms, useSelectHomeTerms } from 'hooks'
import { Link, useParams } from 'react-router-dom'
import STYLE_GUIDE from '../../service/constants/styleGuide'
import styled from 'styled-components'
import { useState } from 'react'

export const TermsWrap = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin-top: -60px;
  }
`
export const TelecomBtn = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      height: 50px;
      width: 50%;
      .active {
        background-color: ${STYLE_GUIDE.COLOR.sub07};
        color: ${STYLE_GUIDE.COLOR.main01};
      }
      > a {
        padding: 15px 20.5px;
        border-radius: 50px;
        height: 50px;
        background-color: ${STYLE_GUIDE.COLOR.sub08};
        color: ${STYLE_GUIDE.COLOR.sub05};
        font-weight: 400;
        font-size: 18px;
        line-height: 50px;
        font-family: GmarketSansMedium, serif;
        margin-right: 12px;
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      height: 40px;
      width: 320px;
      .active {
        background-color: ${STYLE_GUIDE.COLOR.sub07};
        color: ${STYLE_GUIDE.COLOR.main01};
      }
      > a {
        padding: 8px 18px;
        border-radius: 50px;
        height: 50px;
        background-color: ${STYLE_GUIDE.COLOR.sub08};
        color: ${STYLE_GUIDE.COLOR.sub05};
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        font-family: GmarketSansMedium, serif;

        margin-right: 12px;
      }
    }
  }
`
export const TermsTab = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin-top: 40px;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid ${STYLE_GUIDE.COLOR.grey01};
      > a {
        margin-right: 10px;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: ${STYLE_GUIDE.COLOR.grey03};
      }
      .active {
        padding-bottom: 12px;
        color: ${STYLE_GUIDE.COLOR.grey01};
        border-bottom: 2px solid ${STYLE_GUIDE.COLOR.grey01};
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin-top: 30px;
      width: 320px;
      padding-bottom: 10px;
      border-bottom: 1px solid ${STYLE_GUIDE.COLOR.grey01};
      > a {
        margin-right: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: ${STYLE_GUIDE.COLOR.grey03};
      }
      .active {
        padding-bottom: 12px;
        color: ${STYLE_GUIDE.COLOR.grey01};
        border-bottom: 2px solid ${STYLE_GUIDE.COLOR.grey01};
      }
    }
  }
`

const Terms = () => {
  const { data } = useGetHomeTerms()
  const [termschage, setTermschange] = useState(0)
  const { telecom, termsIdx } = useParams()
  const selectedTerm = useSelectHomeTerms(data, telecom, termsIdx)

  const TermsSubTab = (option: number) => {
    setTermschange(option)
  }

  return (
    <TermsWrap>
      <TelecomBtn>
        <Link
          className={`${termschage === 0 && 'active'}`}
          to='/terms/0/1'
          onClick={() => {
            TermsSubTab(0)
          }}
        >
          SKT
        </Link>
        <Link
          className={`${termschage === 1 && 'active'}`}
          to='/terms/1/1'
          onClick={() => {
            TermsSubTab(1)
          }}
        >
          KT
        </Link>
        <Link
          className={`${termschage === 2 && 'active'}`}
          to='/terms/2/1'
          onClick={() => {
            TermsSubTab(2)
          }}
        >
          LG U+
        </Link>
      </TelecomBtn>
      <TermsTab>
        <Link to={`/terms/${termschage}/1`} className={`${termsIdx === '1' && 'active'}`}>
          서비스 이용약관
        </Link>
        <Link to={`/terms/${termschage}/2`} className={`${termsIdx === '2' && 'active'}`}>
          개인정보처리방침
        </Link>
      </TermsTab>
      {(data != null) ? <div dangerouslySetInnerHTML={{ __html: selectedTerm }} /> : '데이터없음'}
    </TermsWrap>
  )
}

export default Terms