import { useValidate } from 'hooks'
import { RefObject, useEffect, useRef, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { CertCompleteState, ErrtimerReset, mvnoData } from 'recoil/common/atom'
import { otpCryptoNumber, otpPNumber, otpTimeOut, otpTelecom } from 'recoil/otp/atom'
import { btnDisabled, inputDisabled } from 'recoil/validate/atom'
import AutocallBtn from '../AutocallBtn'
import AutocallInput from './AutocallInput'
import Utils from "utils";
import MvnoModal from "components/Common/MvnoModal";

export interface CertPartPropsType {
  onSendOTP: () => Promise<boolean>
  onCertOTP: () => any
  isCertBtn?: boolean
  certRef?: RefObject<HTMLInputElement>
  onMvnoCheck?: () => Promise<CheckMvnoMemberBody | undefined>
}

const CertPart = ({ onSendOTP, onCertOTP, isCertBtn = false, certRef, onMvnoCheck }: CertPartPropsType) => {
  const [pNumber, setPNumber] = useRecoilState(otpPNumber)
  const [crypto, setCryptoNumber] = useRecoilState(otpCryptoNumber)
  const { join, replace } = useValidate()
  const { phoneNumber, certNumber } = join
  const { replacePNumber, replaceCertNumber } = replace
  const setSendBtnDisabled = useSetRecoilState(inputDisabled('sendBtn'))
  const setCertBtnDisabled = useSetRecoilState(btnDisabled('certBtn'))
  const setPhoneInput = useSetRecoilState(inputDisabled('phoneInput'))
  const setCertInput = useSetRecoilState(inputDisabled('certInput'))
  const setTelInput = useSetRecoilState(inputDisabled('telecom'))
  const [timeLeft, setTimeLeft] = useRecoilState(otpTimeOut)
  const timerRef = useRef<NodeJS.Timeout>()
  const [certcomplete, setCertComplete] = useRecoilState(CertCompleteState)
  const [timercon, setTimercon] = useRecoilState(ErrtimerReset)
  const { validFunc } = phoneNumber
  const numberRef = useRef<HTMLInputElement>(null)
  const certButtonRef = useRef<HTMLButtonElement>(null)
  const confirmButtonRef = useRef<HTMLButtonElement>(null)
  const [pTelecom] = useRecoilState(otpTelecom)

  useEffect(() => {
    if (timercon) {
      clearInterval(timerRef.current)
      setTimeLeft(180)
    }
    return () => {
      setTimercon(false)
      setCryptoNumber('')
      setPNumber('')
      clearInterval(timerRef.current)
      setTimeLeft(180)
      setCertComplete(false)
    }
  }, [setPNumber, setCryptoNumber, setTimeLeft, timercon, setTimercon, setCertComplete])




  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft > 0) {
          return prevTimeLeft - 1
        } else {
          // certNumber.recoilState[1]({ valid: true, msg: '202' })
          // phoneNumber.recoilState[1]({ valid: false, msg: '202' })
          return prevTimeLeft
        }
      })
    }, 1000)
  }

  const executeOTP = async () => {
    if (await onSendOTP()) {
      if (timeLeft !== 180 && timeLeft > 160) {
      } else if (timeLeft === 180 || timeLeft <= 160) {
        phoneNumber.recoilState[1]({ valid: false, msg: '100' })
        clearInterval(timerRef.current)
        startTimer()
      } else {
        certNumber.recoilState[1]({ valid: true, msg: '202' })
        phoneNumber.recoilState[1]({ valid: false, msg: '202' })
      }
    } else {
      clearInterval(timerRef.current)
      setTimeLeft(180)
    }
  }

  const handleSendBtnClick = () => {
    const buttonEle = certButtonRef.current
    const inputEle = numberRef.current
    if(buttonEle) buttonEle.blur()
    if(inputEle) inputEle.blur()
    validFunc(pNumber)
    setTimercon(false)
    if (pNumber.length < 11) {
      phoneNumber.recoilState[1]({ valid: false, msg: '200' })
    } else {
      // KT MVNO 확인
      if (pTelecom === 1 && onMvnoCheck) {
        onMvnoCheck().then((res) => {
          if(res?.Type === 1) {
            // 알뜰폰
            setIsMvnoConfirm( false)
            setMvnoTermsContents(Utils.common.getDecodeStr(res?.Terms))
            setVisible(true)
          } else {
            // 알뜰폰 아님
            executeOTP()
          }
        })
      } else { // 다른 통신사
        executeOTP()
      }
    }
  }
  const handleCertBtnClick = async () => {
    const buttonEle = confirmButtonRef.current
    const inputEle = certRef?.current
    if(buttonEle) buttonEle.blur()
    if(inputEle) inputEle.blur()
    if (crypto.length > 0) {
      if (await onCertOTP()) {
        clearInterval(timerRef.current)
        phoneNumber.recoilState[1]({ valid: false, msg: '101' })
        setCertComplete(true)
        setTimeLeft(0)
        setSendBtnDisabled({ isDisabled: true })
        setCertBtnDisabled({ isDisabled: true })
        setPhoneInput({ isDisabled: true })
        setCertInput({ isDisabled: true })
        setTelInput({ isDisabled: true })
      } else {
        // clearInterval(timerRef.current)
        // setTimeLeft(0)
      }
    }
  }

  const [visible, setVisible] = useState<boolean>(false)
  const [mvnoTermsContents, setMvnoTermsContents] = useState<string>('')
  const [isMvnoConfirm, setIsMvnoConfirm] = useRecoilState(mvnoData)

  const handleConfirmMvno = () => {
    if(isMvnoConfirm) {
      setVisible(false)
      executeOTP()
    } else if(!isMvnoConfirm && window.confirm("서비스 이용을 위하여 약관동의가 필요합니다. 모두 동의하시겠습니까?")) {
      setIsMvnoConfirm(true)
      setVisible(false)
      executeOTP()
    } else {
      return
    }
  }

  return (
    <>
      <MvnoModal visible={visible}
                 onCancel={() => setVisible(false)}
                 onConfirm={handleConfirmMvno}
                 termsContents={mvnoTermsContents}/>
      <div className='input-part'>
        <AutocallInput
          inputRef={numberRef}
          inputType='tel'
          placeholder='‘-’를 제외한 번호를 입력하세요.'
          state={pNumber}
          setState={setPNumber}
          validate={phoneNumber}
          onKeyup={handleSendBtnClick}
          replacer={replacePNumber}
          maxLength={11}
        />
        <AutocallBtn
          buttonRef={certButtonRef}
          title='인증번호'
          type='nomal'
          onClickFunc={handleSendBtnClick}
          disabled={phoneNumber.btnDisabled?.isDisabled}
        />
      </div>
      <div className='input-part'>
        <AutocallInput
          inputRef={certRef}
          inputType='tel'
          placeholder='인증번호 4자리를 입력하세요.'
          state={crypto}
          setState={setCryptoNumber}
          validate={certNumber}
          onKeyup={handleCertBtnClick}
          replacer={replaceCertNumber}
          isCount
          timeRef={timeLeft}
          maxLength={4}
        />
        {isCertBtn && (
          <AutocallBtn
            buttonRef={confirmButtonRef}
            title={certcomplete ? '인증완료' : '확인'}
            type='nomal'
            onClickFunc={handleCertBtnClick}
            disabled={certNumber.btnDisabled?.isDisabled}
          />
        )}
      </div>
    </>
  )
}
export default CertPart