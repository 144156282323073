import { atom, selector } from 'recoil'

export const otpTelecom = atom<Telecom>({
  key: 'otpTelecom',
  default: 0
})
export const otpPNumber = atom<string>({
  key: 'otpPNumber',
  default: ''
})
export const otpAuthType = atom<AuthType>({
  key: 'otpAuthType',
  default: 'join_ready'
})
export const otpCarNumber = atom<string>({
  key: 'otpCarNumber',
  default: ''
})
export const otpFTelecom = atom<Telecom>({
  key: 'otpFTelecom',
  default: 0
})
export const otpFNumber = atom<string>({
  key: 'otpFNumber',
  default: ''
})
export const otpCallType = atom<CallType>({
  key: 'otpCallType',
  default: 'WEB'
})
export const otpAuthToken = atom<string>({
  key: 'otpAuthToken',
  default: ''
})
export const otpjoinready = atom({
  key: 'otpjoinready',
  default: false
})
export const resSendOTP = atom<ResponseSendOTPBody>({
  key: 'resSendOTP',
  default: {
    TimeOut: 0,
    TransactionID: ''
  }
})
export const otpTimeOut = atom<number>({
  key: 'otpTimeOut',
  default: 0
})
export const otpTransctionID = atom<string>({
  key: 'otpTransactionID',
  default: ''
})
export const otpCryptoNumber = atom<string>({
  key: 'otpCryptoNumber',
  default: ''
})
export const otpCouponCode = atom<string>({
  key: 'otpCouponCode',
  default: ''
})
export const otpTermsAgrees = atom<number[]>({
  key: 'otpTermsAgrees',
  default: [0, 0, 0, 0, 0, 0, 0, 0, 0]
})
export const otpJoinTermsList = atom<JoinTerms[]>({
  key: 'otpJoinTermsList',
  default: [
    {
      TermsIdx: 0,
      Terms: [],
      TermsOrder: ""
    },
    {
      TermsIdx: 0,
      Terms: [],
      TermsOrder: ""
    },
    {
      TermsIdx: 0,
      Terms: [],
      TermsOrder: ""
    },
  ]
})

export const otpJoinTermsData = selector<JoinTerms>({
    key: 'otpJoinTermsData',
    get: ({ get }) => get(otpJoinTermsList)?.[get(otpTelecom)],
})

// 필수약관 동의 여부
export const isAgreeAllRequiredTermsData = selector<boolean>({
  key: 'isAgreeAllRequiredTermsData',
  get: ({ get }) => get(otpJoinTermsData)?.Terms
    .filter((terms) => terms.Required === 1 && terms.Order !== undefined)
    .every((terms) => get(otpTermsAgrees)[terms.Order! - 1] === 1) ?? false,
})

export const timer = atom({
  key: 'timer'
})
