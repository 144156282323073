import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { homeData } from 'recoil/common/atom'
import { otpJoinTermsList, otpTelecom, otpTermsAgrees } from 'recoil/otp/atom'
import { APIs } from 'service'
import { CMD_TYPE } from 'service/constants/common'
import useAPI from './useAPI.hook'
import useCookies from './useCookies.hook'

export const useGetFAQs = (group: GroupType) => {
  const data: RequestFaqListBody = {
    Group: group,
    Length: 100,
    Offset: 0
  }
  return useQuery(CMD_TYPE.GET_FAQ_LIST, async () => await APIs.api.requestSRS<FAQ[]>(CMD_TYPE.GET_FAQ_LIST, data), {
    staleTime: 10 * 1000
  })
}

export const useGetNotices = () => {
  const data: RequestNoticeBody = {
    Length: 100,
    Offset: 0
  }
  return useQuery(CMD_TYPE.GET_NOTICE_LIST, async () => await APIs.api.requestSRS<Notice[]>(CMD_TYPE.GET_NOTICE_LIST, data), {
    staleTime: 10 * 1000
  })
}
export const useGetNoticeDetail = (noticeIdx: string) => {
  const api = useAPI()
  const data: RequestNoticeDetailBody = {
    Idx: noticeIdx
  }
  return useQuery(CMD_TYPE.GET_NOTICE_DETAIL, async () => await api.requestSRS<Notice>(CMD_TYPE.GET_NOTICE_DETAIL, data), {
    staleTime: 10 * 1000
  })
}
export const useGetNews = () => {
  const data: RequestNewsBody = {
    Length: 100,
    Offset: 0
  }
  return useQuery(CMD_TYPE.GET_NEWS_LIST, async () => await APIs.api.requestSRS<News[]>(CMD_TYPE.GET_NEWS_LIST, data), {
    staleTime: 10 * 1000
  })
}

export const useGetHomeTerms = () => {
  const data: HttpBody = {}
  return useQuery(CMD_TYPE.GET_HOME_TERMS, async () => await APIs.api.requestSRS<TermsResponse<HomeTerms>>(CMD_TYPE.GET_HOME_TERMS, data), {
    // staleTime: 10 * 1000,
  })
}

export const useGetJoinTerms = () => {
  const data: HttpBody = {}
  console.log('sfjkl;sdfjsdklfl')

  const setOrder = (terms: JoinTerms) => ({
    ...terms,
    Terms: terms.Terms.map((detail, index) => ({
      ...detail,
      Required: detail.Required ?? 0,
      Order: Number(terms.TermsOrder.charAt(index)),
    })),
  } as JoinTerms)

  return useQuery(CMD_TYPE.GET_JOIN_TERMS, async () => {
    console.log('useQuery')
    const results = await APIs.api.requestSRS<TermsResponse<JoinTerms>>(CMD_TYPE.GET_JOIN_TERMS, data)
    if (results) {
      return [
        setOrder(results.SKTTerms),
        setOrder(results.KTTerms),
        setOrder(results.LGUPTerms),
      ]
    }
    return [
      {
        TermsIdx: 0,
        Terms: [],
        TermsOrder: ""
      },
      {
        TermsIdx: 0,
        Terms: [],
        TermsOrder: ""
      },
      {
        TermsIdx: 0,
        Terms: [],
        TermsOrder: ""
      },
    ]
  })
}

export const useSelectHomeTerms = (
  data: TermsResponse | undefined,
  telecom: string | undefined,
  termsIdx: string | undefined
): string => {
  const termsData: HomeTerms[] | undefined = (data != null)
    ? [
        data.SKTTerms,
        data.KTTerms,
        data.LGUPTerms
      ]
    : undefined

  const terms = (telecom: string | undefined, termsIdx: string | undefined): string => {
    try {
      return termsData ? termsData[Number(telecom)].Terms[Number(termsIdx) - 1].Contents : ''
    } catch(error) {
      console.log(error)
      return ''
    }
  }
  return terms(telecom, termsIdx)
}

export const useAllRequiredTermsAgree = () => {
  const telecom = useRecoilValue(otpTelecom)
  const joinTermsList = useRecoilValue(otpJoinTermsList)
  const termsAgrees = useRecoilValue(otpTermsAgrees)

  return joinTermsList?.[Number(telecom)]?.Terms
    .filter((terms) => terms.Required === 1 && terms.Order !== undefined)
    .every(({ Order }) => termsAgrees[Order! - 1] === 1) ?? false
}

export const useGetHomeDatas = () => {
  const datas = useRecoilValue(homeData)
  const data: HttpBody = {}
  const result = useQuery(CMD_TYPE.GET_HOME_DATA, async () =>
    await APIs.api.requestSRS<ResponseHomeBody>(CMD_TYPE.GET_HOME_DATA, data)
  )
  function news () {
    if (datas != null) {
      return datas.News
    }
  }
  function notice () {
    if (datas != null) {
      return datas.Notice
    }
  }
  function popup () {
    if (datas != null) {
      return datas.Popup
    }
  }
  return {
    query: result,
    news,
    notice,
    popup
  }
}

export const useLoginData = () => {
  const cookie = useCookies()
  const { PNumber, SessionID } = cookie.getSessionCookie()
  // "console.log()
  // cookie.login(PNumber, result.)

  const data: RequestSessionCheckBody = {
    PNumber,
    SessionID
  }
  return useQuery(CMD_TYPE.SESSION_CHECK, async () => await APIs.api.requestSRS<ResponseLoginBody>(CMD_TYPE.SESSION_CHECK, data))
}
export const useStickerData = () => {
  const cookie = useCookies()
  const { PNumber, SessionID } = cookie.getSessionCookie()
  const data: RequestStickerHistoryInquiryBody = {
    PNumber,
    SessionID
  }
  return useQuery(
    CMD_TYPE.STICKER_HISTORY_INQUIRY,
    async () => await APIs.api.requestSRS<ResponseStickerHistoryInquiryBody>(CMD_TYPE.STICKER_HISTORY_INQUIRY, data)
    // { staleTime: 10 * 1000 },
  )
}